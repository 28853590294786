import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import PlainContent from "@/components/plain-content";
import { faqData } from "@/data";

const FAQPage = () => {
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle="FAQ Page">
                    <HeaderOne />
                    <PageBanner title="FAQ" name="FAQ" />
                    <PlainContent title="" content={faqData} />
                    <Footer />
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default FAQPage;